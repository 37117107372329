<template>
  <div class="source-logo">
    <div class="logo-container">
      <img class="logo-image" :src="require(`@/assets/bank-logos/${source.logoFileStacked}`)">
    </div>
    <div class="source-info">
      <div class="source-display-name">
        {{ name || source.displayName }}
      </div>
      <div v-if="idNumber && !budgetzSource" class="source-number-id">
        ת.ז. {{ idNumber }}
      </div>
    </div>
  </div>
</template>

<script>

import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';

export default {
  name: 'SourceLogo',
  props: {
    sourceName: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
    idNumber: {
      type: String,
      require: false,
    },
  },
  computed: {
    source() {
      return getSourceConfigurationBySourceName(this.sourceName);
    },
    budgetzSource() {
      // This is done  because we create a random idNumber in elsa for new budgetz source as a hack
      // You can refer to it in OpenBankingDetails.vue - saveOpenBanking() function
      return this.sourceName === 'budgetz';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../scss/mixins';

  .source-logo {
    .logo-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 20px;

      .logo-image {
        height: 36px;
      }
    }

    .source-info {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 18px;
      align-items: center;
      font-family: SimplerPro;
      color: $riseup_black;
    }

    .source-display-name {
      text-align: right;
    }

    .source-number-id {
      text-align: left;
    }
  }
</style>
